import WebServiceRequest from "../Api/WebServiceRequest";

class GetAllBlogCategories extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class CreateBlogCategory extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/CreateBlogCategory");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}
class BlogCategoryGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/GetAll");
  }
}
class BlogCategoryCreateRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/Create");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}

class BlogCategoryDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
  }
  setId(id) {
    super.setTag("BlogCategory/DeleteBy?BlogCategoryId=" + id);
  }
}

class UpdateABlogCategory extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/UpdateBlogCategory");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class UpdateABlogCategorySeo extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/UpdateSeo");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

class GetASingleBlogCategory extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("BlogCategory/GetBy");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

export {
  GetAllBlogCategories,
  CreateBlogCategory,
  UpdateABlogCategory,
  BlogCategoryGetAllRequest,
  BlogCategoryCreateRequest,
  BlogCategoryDeleteRequest,
  UpdateABlogCategorySeo,
  GetASingleBlogCategory,
};
